import { template as template_234395d56a9f47b69dc6d4df9b15ffaa } from "@ember/template-compiler";
const FKControlMenuContainer = template_234395d56a9f47b69dc6d4df9b15ffaa(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
