import { template as template_c5e39a84998d472eba3245f2b7d17cc8 } from "@ember/template-compiler";
const SidebarSectionMessage = template_c5e39a84998d472eba3245f2b7d17cc8(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
